import { BSpinner } from "bootstrap-vue";
import {actions, subjects} from "@/libs/acl/rules";
import { getDashboardFilterOptions, getDashboardData, getVaccinatorTableData } from '@/views/custom-pages/dashboard/requests';
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import NoCampaignFound from '@/views/custom-pages/dashboard/shared-components/NoCampaignFound.vue'
import NoDataFound from '@/views/custom-pages/dashboard/shared-components/NoDataFound.vue'

export default {
	components: {
		BSpinner,
		NoCampaignFound,
		NoDataFound
	},

	mixins: [ dashUtilsMixin ],

    data() {
		return {
			isLoading: true,
			perfil: "",
            perfilRoute: "",
			filterFields: {},
			campaignExists: true,
			filterSearchParams: {},
            updatedAt: `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`,
			dashboardData: {},
			tableData: {},
            beforeFirstFilter: true,
			dataLoaded: false,
			vaccinatorDash: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_VACINADOR),
			dnDashboard: this.$can(actions.VISUALIZAR, subjects.DASHBOARD_DN),
		}
	},

    created() {
		if(this.vaccinatorDash){
			this.perfilRoute = "vacinador"
		}else{
			this.perfilRoute = "dn-dr-uo"
		}
    },

	async beforeMount() {
		await this.populateFilterFields()
		this.populateSearchParams()

		if (!this.dnDashboard) {
			await this.updateDashboardData(this.filterSearchParams)
		}
		
		this.$emit("loadFinished", true)
		this.isLoading = false
	},

    methods: {
		async populateFilterFields() {
			try {
				const response = await getDashboardFilterOptions(this.perfilRoute)
				this.filterFields = response.data
			} catch (error) {
				console.error('Erro ao buscar dados para popular filtro:', error)
			}
		},

		populateSearchParams() {
			const allDR = this.filterFields.userDR
			const allUO = this.filterFields.userUO.sort((a, b) => a.id - b.id)

			if (this.filterFields.userCampaigns.length == 0) {
				this.campaignExists = false
				return
			}

			const allCampaigns = this.filterFields.userCampaigns.sort((a, b) => a.id - b.id)

			this.filterSearchParams = {
				dr: allDR.map(dr => dr.id),
                uo: allUO.map(uo => uo.id),
                campaigns: allCampaigns.map(uo => uo.id),
				startDate: `01/01/${moment().year()}`,
                endDate: moment().format('DD/MM/YYYY')
			}
		},

		async updateDashboardData(filterParams) {
			if (!this.campaignExists) return
			this.dataLoaded = false
			let params = { ...filterParams }
			params.startDate = this.standardDateFormat(params.startDate)
			params.endDate = this.standardDateFormat(params.endDate)

			try {
                this.beforeFirstFilter = false
				const response = await getDashboardData(this.perfilRoute, params)
				this.dashboardData = response.data

				if (this.vaccinatorDash) {
					const response2 = await getVaccinatorTableData(params)
					this.tableData = response2.data
				}

				this.dataLoaded = true
				this.updatedAt = `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`
				if (!this.isLoading) this.$refs.filterComponent.generateActiveFilterTags();

			} catch (error) {
				console.error('Erro ao buscar dados do dashboard:', error);
			}
		},

		// Returns true if ANY of the conditions is true. Otherwise, returns false.
		dashboardHasData() {
			return (
				this.dashboardData.vacinacao.colaboradores_vacinados > 0 ||
				this.dashboardData.adesoes_por_empresas.empresas_adesao > 0 ||
				this.dashboardData.doses_aderidas.total_doses > 0 ||
				this.dashboardData.distribuicao_doses.disponiveis > 0 ||
				this.dashboardData.distribuicao_doses.distribuidas > 0 ||
				this.dashboardData.adesoes_por_vacinas.some(vacina => vacina.quantidade > 0) ||
				this.dashboardData.vacinacao_por_colaborador.trabalhadores > 0 ||
				this.dashboardData.vacinacao_por_colaborador.dependentes > 0 ||
				this.dashboardData.vacinacao_por_colaborador.terceiros > 0
			)
		}
	}
}